import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { BlockMore, BlockMoreProps, Card, MdxContent, Section, TableOfContentsItem, ToC } from "../components";
import { StyleShape2 } from "../components/Shapes";
import Theme from "../components/Theme";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;
    ::before {
      ${StyleShape2};
      z-index: ${Theme.zIndex.header + 1};
      top: -50px;
      left: -400px;
    }
  `,
  layout: css`
    ${Theme.mq.md_} {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: min-content 4fr;
      grid-gap: 48px;
    }

    ${Theme.mq.lg_} {
      grid-template-columns: max-content 4fr;
      grid-gap: 60px;
    }
  `,
  card: css`
    position: sticky;
    padding: 48px;
    top: ${Theme.page.headerHeight[Theme.page.sm]}px;
    height: max-content;

    ${Theme.mq._sm} {
      display: none;
    }
  `,
};

interface PageProps {
  data: {
    mdx: {
      body: string;
      tableOfContents: {
        items: Array<TableOfContentsItem>;
      };
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
        more: BlockMoreProps;
      };
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, more },
      tableOfContents,
      body,
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords} smoothScrolling overflowHidden={false}>
    <header css={styles.base}>
      <BlockMore alignment="center" {...more} {...hero} />
    </header>
    <main>
      <Section marginTop marginBottom sx={styles.layout}>
        <Card sx={styles.card}>
          <ToC items={tableOfContents.items} />
        </Card>
        <MdxContent body={body} />
      </Section>
    </main>
  </PageLayout>
);

export const query = graphql`
  query SecurityQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/security.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
        }
        more {
          items {
            image
            heading
            content
            action {
              text
              href
            }
          }
        }
      }
      tableOfContents
      body
    }
  }
`;
